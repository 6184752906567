import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

window.addEventListener('load', ()=>{
    faqToggle();
    faqAnimations();
});

function faqToggle() {
    const block = document.querySelector('.faq-block');

    if (block) {
        const faqs = block.querySelectorAll('.faq-wrapper');

        faqs.forEach(faq => {
            const button = faq.querySelector('.faq-label');

            button.addEventListener('click', ()=>{
                faq.classList.toggle('open');
            })
        });
    }
}

function faqAnimations() {
    const block = document.querySelector('.faq-block');

    if (block) {
        const cards = block.querySelectorAll('.faq-wrapper');

        cards.forEach((card) => {
            gsap.from(card, {
                scrollTrigger: card,
                opacity: 0,
                transitionDelay: "1s",
                transitionDuration: "2s",
            })
        });
    }
}